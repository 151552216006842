.patent-search-form {
  .search-form__date-delimiter {
    line-height: 32px;
    font-weight: bolder;
  }

  .ant-form-item-label {
    label {
      font-weight: bolder;
      min-height: 36px;
      height: 36px;
      white-space: break-spaces;
      align-items: flex-end;
    }
  }

  .ant-select-selection-item-remove {
    display: flex;
    align-items: center;
  }

  .centered-label {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: right;
  }
}
