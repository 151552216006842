.field__label {
  font-weight: bold;
}

.data-field {
  .ant-form-item-label {
    text-overflow: initial;
    white-space: normal;
    label {
      font-weight: bolder;
    }
  }

  .data-filed__data-item {
    word-break: break-word;
    text-align: justify;
  }
}

