//@import "bootstrap/scss/bootstrap-grid";

@import '~antd/dist/antd.css';
@import "variables";

@import "../components/DataFieldWithLabel/DataFieldWithLabel.scss";
@import "../containers/DetailsCard/ProceedingDetailsCard.scss";
@import "../containers/Table/PatentTable/PatentTable.scss";
@import "../containers/Table/GeneralTable/GeneralTable.scss";
@import "../containers/SearchForm/ProceedingSearchForm.scss";
@import "../containers/SearchForm/PatentSearchForm.scss";
@import "../containers/Navbar/PageNavbar.scss";


.pointer {
  cursor: pointer;
}

.color--red {
  color: #D73E3E;
}

.font-italic {
  font-style: italic;
}

.height-50vh {
  height: 50vh;
}

.proceeding-search {

  .ant-tabs-content {
    border-style: solid;
    border-color: #d2d3d8;
    border-radius: 4px;
    border-width: 0.5px;
    border-top: none;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 20px;
  }

  .ant-tabs-top > .ant-tabs-nav {
    margin: 0;
  }

  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: 5px solid $color-blue-primary;
  }

  .ant-tabs-card > .ant-tabs-nav {
    .ant-tabs-tab {
      border-radius: 6px;
      border: 3px solid $color-blue-primary;
      border-bottom: 5px solid $color-blue-primary;
    }

    .ant-tabs-tab-active {
      background-color: $color-blue-primary;

      .ant-tabs-tab-btn {
        color: white;
      }
    }
  }

}

.fixed-search {
  width: 100%;
  height: 36px;
  position: sticky;
  bottom: 0px;
  z-index: 10;
  margin-bottom: 0px;
  background-color: white;
  .ant-form-item-control-input-content {
    display: flex;
  }
}

@media (min-width: 576px) {
  .fixed-search {
    max-width: 480px;
  }
}

@media (min-width: 768px) {
  .fixed-search {
    max-width: 665px;
  }
}

@media (min-width: 992px) {
  .fixed-search {
    max-width: 908px;
  }
}

@media print {

  #timeline {
    display: none;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .data-field-link-to-page {
    overflow-wrap: break-word;
    word-break: break-all;
    white-space: normal;
    text-overflow: ellipsis;
  }

}
