@import "../../../styles/variables";


.general-table {
  .ant-card-body {
    padding: 0;
  }

  .ant-card-head {
    background-color: $color-blue-primary;
    color: white;
    border-radius: 4px 4px 0 0;

    .ant-card-head-title {
      font-size: 16px;
    }
  }
}
