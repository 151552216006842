.page-main-header {

  .ant-layout-header {
    background-color: white;
    padding-left: 0;
  }

  .logo-container {
    img {
      margin-right: 15px;
    }
  }
}
