@import "../../styles/variables";

.proceeding-card {

  .ant-card-bordered {
    border-radius: 4px;
  }

  .ant-card-head {
    background-color: $color-blue-primary;
    color: white;
    border-radius: 4px 4px 0 0;

    .ant-card-head-title {
      font-size: 16px;
    }
  }

}
